@import '../common/variables.scss';

#navbar {
    position: sticky;
    top: 0px;
    height: fit-content;
    z-index: 1;
}

#navbar-content {
    display: grid;
    grid-template-columns: 1fr 20vw 1fr;
    box-shadow: 0 2px 4px 0 rgba($dark-blue, .4);
    padding: 0 7% 0 7%;
    -webkit-transition: all ease-in-out .4s;
    transition: all ease-in-out .4s;
    height: 12vh;
    background-color: rgba($white, 0.9);
    position: sticky;
    align-items: center;
    top: 0px;
    z-index: 1;
}

#left-nav {
    grid-column: 1 / 2;
}

#nav-logo {
    height: 10vh;
    -webkit-transition: all ease-in-out .4s;
    transition: all ease-in-out .4s;
    grid-column: 2 / 3;
    cursor: pointer;
}

#title {
    margin: 0;
    background-color: transparent;
    color: $black;
    font-weight: bolder;
    font-family: $header-font;
    font-size: 30px;
}

#right-nav {
    grid-column: 3 / 4;
}

.links {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav-links {
    display: inline-block;
    color: $black;
    font-weight: bolder;
    font-family: $header-font;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
}





#mobile-nav {
    height: 8vh;
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1.5em;
}

#mobile-nav-logo {
    height: 65%;
    margin-right: auto;
}

#hamburger-menu-layout {
    display: flex;
}

#menu-container {
    min-width: 0px;
    max-width: 44%;
    height: 100%;
    background-color: $white;
    box-shadow: -2px 0 2px rgba($dark-blue, 0.3);
    z-index: 90;
    position: fixed;
    top: 0;
    right: 0;
    padding: 1em 2em;
}

#menu-btn {
    z-index: 99;
    cursor: pointer;
    color: $dark-blue;
    background-color: transparent;
    border: none;
    
}

#menu-svg {
    margin-top: 35%;
}

#nav-menu-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    
}

#nav-list {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
}


@media screen and (max-width: 660px) {
    .nav-links {
        font-size: 1.2em;
        list-style-type: none;
        margin: 5px 0;
    }
}