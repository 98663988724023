@import '../common/variables.scss';

#details-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    padding: 0 7% 0 7%;
}

#details-title {
    color: $black; 
    margin: 0;
    margin-top: 4%;
    margin-bottom: 8%;
    font-size: 2.5rem;
    text-align:center;
}

#details-inner-layout {
    display: flex;
    justify-content: space-between;
    width: 75%;
}

.details-item-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 28%;
    min-width: 238px;
    max-width: 265px;
    color: $black;
}

.details-header {
    margin-bottom: 0;
    letter-spacing: 0.03em;
}

.details-text {
    margin-bottom: 0;
    letter-spacing: 0.02em;
    line-height: 1.5rem;
    font-size: 1.2rem;
    font-weight: 400;
}

.box-icons {
    color: $black;
    min-width: 2em;
    min-height: 2em;
}

@media screen and (max-width: 660px) {
    #details-layout {
        height: fit-content;
    }

    #details-inner-layout {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin: 10% 0 10% 0;
    }

    .details-header {
        margin-top: 5px;
        font-size: 1.5em;
    }

    .details-text {
        font-size: 1em;
    }
}

@media screen and (max-width: 1100px) and (min-width: 661px) {
    #details-layout {
        height: fit-content;
    }

    #details-inner-layout {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        place-items: center;
        margin: 10% 0 10% 0;
        gap: 15px;
    }

    #friendly {
        grid-column: 1 / 3;
    }

    .details-header {
        margin-top: 5px;
    }
}
