@import '../common/variables.scss';

#faq-layout {
    // Assuming you want the layout to be centered and not exceed a max width
    max-width: 960px;
    margin-top: 10vh;
    margin: 0 auto;
    padding: 0 20px; // gives some space on the sides
    margin-bottom: 10vh;

    h1#faq-title {
        color: $black; // Ensure $black is defined in your SCSS variables or replace with a hex color
        letter-spacing: 0.05em;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 30px;
    }
    
    .faq-container {
      .faq-item {
        margin-bottom: 20px;

        .faq-question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding-left: 10px;
            // Prevent question text from wrapping around the plus sign
            flex-wrap: nowrap;
            letter-spacing: 0.02em;
            line-height: 1.5rem;
            font-size: 1.2rem;
            font-weight: 400;
  
          .faq-text {
            margin: 0;
            flex-grow: 1; // Allows text to grow and fill available space
            white-space: normal; // Ensures text wraps in the container
          }
  
          .faq-toggle {
            flex-shrink: 0; // Prevents the plus sign from shrinking
            padding-left: 16px; // Ensures space between the text and the plus sign
            font-size: 24px;
          }
        }
  
        .faq-answer {
          display: none;
          padding-top: 5px;
          padding-left: 10px;
          word-break: break-word; // Again, ensuring text wraps
          font-weight: 400;
          line-height: 1.7rem;
          font-size: 1.2rem;
          
          &.active {
            display: block;
          }
        }
  
        hr {
          border: none;
          border-top: 1px solid #ccc;
          margin-top: 10px;
        }
      }
    }
  
    // Responsive design for smaller devices
    @media (max-width: 768px) {
      max-width: 100%;
      padding: 0 10px; // smaller padding for smaller devices
  
      .faq-container {
        .faq-item {
            margin-bottom: 20px; // Adds space below each question
        &:last-child {
            margin-bottom: 0; // Removes the margin from the last item
        }
          .faq-question {
            flex-direction: row; // Keep them in a row instead of stacking them
            align-items: center; // Center-align the items
            padding: 0 10px;
  
            .faq-text {
                flex-grow: 1; // Allow the text to grow and push the toggle to the edge
            }

            .faq-toggle {
                flex-shrink: 0; // Prevent the toggle from shrinking
            }
          }
        }
      }
    }
  }
  