@import '../common/variables.scss';

#hero-body {
    height: 80vh;
    display: flex;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

#left-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

#title-block {
    width: 65%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#hero-text {
    color: $white;
    text-shadow: 3px 3px $black;
    margin: 0;
    font-size: 5em;
    min-width: 422px;
}

#rental-button {
    background-color: $orange;
    border: none;
    border-radius: 40px;
    font-family: $header-font;
    color: $white;
    font-size: 1.5rem;
    padding: 20px;
    width: 100%;
    cursor: pointer;
    min-width: 362px;
}

@media screen and (max-width: 660px) {
    #hero-body {
        height: fit-content;
        min-height: 50vh;
        flex-direction: column;
        align-items: center;
    }

    #left-hero {
        width: 50%;
        align-items: center;
        justify-content: center;
    }

    #title-block {
        width: 50vw;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 6%;
    }

    #hero-text {
        font-size: 2em;
        text-shadow: 2px 2px $black;
        width: 50vw;
        min-width: 255px;
    }

    #rental-button {
        background-color: $orange;
        border: none;
        border-radius: 40px;
        font-family: $header-font;
        color: $white;
        font-size: 1rem;
        padding: 5px;
        width: 50%;
        cursor: pointer;
        min-width: 228px;
    }
}

@media screen and (max-width: 800px) and (min-width: 661px) {
    #hero-body {
        max-height: 65vh;
        background-position: top 25%;
     } 

    #hero-text {
        font-size: 3.8em;
        width: 535px;
        margin-bottom: 1%;
    }
}

@media screen and (max-width: 1050px) and (min-width: 661px) {
    #hero-body {
       height: fit-content;
       min-height: 70vh;
       flex-direction: column;
       align-items: center;
    } 

    #left-hero {
        width: 80%;
        align-items: center;
        justify-content: center;
    }

    #title-block {
        width: 80%;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-width: 635px;
        margin-bottom: 4%;
    }

    #rental-button {
        padding: 10px;
    }
}