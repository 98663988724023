@import '../common/variables.scss';

#testimonials-layout {
    height: 90vh;          
    width: 100vw;
    background-color: $white;
    display: grid;
    grid-template-rows: 10% 90%;
    justify-items: center;
    margin-top: 10vh;
}

#testimonials-title {
    color: $black; 
    margin: 0;
    margin-top: 4%;
    font-size: 2.5rem;
}

#card-layout {
    display: flex;
    justify-content: space-between;
    width: 75%;
    height: 80%;
}

.testimonial-card-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#testimonial-pic {
    border-radius: 50%;
    height: 100px;
    position: relative;
    top: 10%;
    border: solid 7px $white;
}

#card {
    background-color: $light-grey;
    display: grid;
    grid-template-rows: 70% 1% 25%;
    width: 20vw;
    min-width: 285px;
    height: 85%;
    min-height: 392px;
    max-height: 400px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#testimonial-text {
    text-align: center;
    font-weight: 400;
    margin-top: 22%;
    padding: 0 8% 0 8%;
    line-height: 1.7rem;
    margin-bottom: 0;
}

#line {
    width: 70%;
    border-top: 1px solid $dark-blue;
    border-bottom: none;
    margin-bottom: 0;
    grid-row: 2 / 3;
    position: relative;
}

#name {
    text-align: center;
    font-weight: 700;
    padding: 4% 8% 0 8%;
    margin-top: 5px;
    grid-row: 3 / 4;
    margin-bottom: 3%;
}

#house {
    padding: 0 5% 0 5%;
    grid-row: 3 / 4;
    font-weight: 400;
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
    margin: 0;
}

@media screen and (max-width: 660px) {
    #testimonials-layout {
        height: fit-content;
        grid-template-rows: 10vh auto;
    }

    #testimonials-title {
        margin-top: 4%;
        font-size: 2rem;
    }

    #bryan.testimonial-card-layout {
        margin-top: -30px;
    }

    #grant.testimonial-card-layout {
        margin-bottom: 30px;
    }

    #card-layout {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 75%;
        height: 80%;
    }

    #card {
        background-color: $light-grey;
        display: grid;
        grid-template-rows: 70% 1% 25%;
        width: 20vw;
        min-width: 265px;
        min-height: 300px;
        max-height: 350px;
    }

    #testimonial-text {
        text-align: center;
        margin-top: 17%;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.4rem;
        margin-bottom: 0;
    }

    #testimonial-pic {
        position: relative;
        height: 88px;
        top: 10%;
        border: solid 7px $white;
    }  
    
    #house {
        font-size: 0.75rem;
        text-align: center;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 661px) {
    #testimonials-layout {
        height: fit-content;
        grid-template-rows: 10vh auto;
    }

    #card-layout {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        justify-items: center;
        margin: 0 0 10% 0;
        height: fit-content;
    }

    #card {
        display: grid;
        grid-template-rows: 70% 1% 25%;
        width: 20vw;
        min-width: 285px;
        min-height: 300px;
        max-height: 350px;
    }
    
    #testimonial-text {
        margin-top: 17%;
        font-size: 0.9rem;
        line-height: 1.4rem;
    }

    #testimonial-pic {
        position: relative;
        height: 88px;
        top: 10%;
        border: solid 7px $white;
    }  
    
    #house {
        font-size: 0.8rem;
        text-align: center;
        margin-bottom: 15px;
    }  

    #grant {
        grid-column: 1 / 3;
    }
}
