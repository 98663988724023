@import './variables.scss';

body {
    margin: 0;
    background-color: $white;
    width: 100vw;
    overflow-x: hidden;
    overflow: overlay;
}

h1 {
    background-color: transparent;
    color: $black;
    font-weight: bolder;
    font-family: $header-font;
    font-size: 30px;
}

h2 {
    display: inline-block;
    color: $black;
    font-weight: bolder;
    font-family: $header-font;
    font-size: 20px;
}

p {
    background-color: transparent;
    color: $black;
    font-family: $p-font;
    font-weight: 600;
}

.showBtn {
  opacity: 1;
  transform: translateY(0);
}

#scrollBtn {
   position: fixed; 
   display: flex;
   justify-content: center;
   width: 35px;
   height: 36px;
   border-radius: 50%;
   font-family: $p-font;
   left: 40px;
   bottom: 40px;
   z-index: 100;
   transition: all .3s ease;
   cursor: pointer;
   background-color: rgba($white, 0.9);
   color: $orange;
   border: 1px solid $orange;
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    margin: 20px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba($dark-blue, .75);
    border-radius: 20px;
    width: 10px;
  }

