@import '../common/variables.scss';

#footer-layout {
    height: 10vh;
    min-height: 50px;
    background-color: $dark-blue;
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
}

#footer {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
}

.footer-text {
    color: $white;
    font-weight: 400;
    letter-spacing: 0.02rem;
}

@media screen and (max-width: 660px) {
    #footer-layout {
         height: 10vh;
    }

    #footer {
        flex-direction: column;
        justify-content: center;
        row-gap: 5px;
    }

    .footer-text {
        font-size: 0.8rem;
        margin: 0;
    }

    #left {
        text-align: center;
    }

    #right {
        text-align: center;
    }
}