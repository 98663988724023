@import '../common/variables.scss';

#availability-layout {
    height: fit-content;
    width: 100vw;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#unit-grid-layout {
    padding: 20px;
}

#availability-title-layout {
    display: flex;
    align-items: center;
}

#availability-title {
    color: $black;
    font-family: $p-font;
    margin: 0;
    margin-bottom: 2%;
    font-size: 2.5rem;
}

//Phone screen
@media screen and (max-width: 660px) {
    #carousel-layout {
        margin-top: 10%;
    }

    #availability-title-layout {
        display: block;
    }

    #availability-title {
        font-size: 1.4rem;
        margin-bottom: 3%;
    }

    #unit-grid { grid-template-columns: unset; }

    .unit-grid {
        grid-template-columns: 1fr; /* 1 column */
    }

    .filters {
        flex-direction: column; /* Stack filters vertically */
        align-items: center; /* Center filters horizontally */
    }
}

@media (max-width: 440px) {
    .modal {
      width: 95%; /* Further reduce width for very small screens */
      max-width: 95%; /* Ensure it doesn't exceed 95% */
      padding: 10px; /* Maintain padding for better spacing */
      margin: 0 auto; /* Center the modal */
    }

    #unit-grid { grid-template-columns: unset; }

    .unit-grid {
        grid-template-columns: 1fr;
    }

    .filters {
        flex-direction: column; /* Stack filters vertically */
        align-items: center; /* Center filters horizontally */
    }
}

@media screen and (max-width: 800px) {
    .modal {
      width: 95%; /* Reduce width for mobile screens */
      max-width: 95%; /* Ensure it doesn't exceed 95% */
      padding: 10px; /* Adjust padding for better spacing */
    }

    #unit-grid { grid-template-columns: unset; }

    .unit-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .filters {
        flex-direction: column; /* Stack filters vertically */
        align-items: center; /* Center filters horizontally */
    }

    .filter {
        align-items: center;
    }
  }

//mid-size like tablet
@media screen and (max-width: 1200px) and (min-width: 801px) {
    #availability-title-layout {
        display: block;
    }
}

.filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.filter {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: $header-font;
    font-size: 1rem;
}

#date-picker {
    border-radius: 4px;
    border: 1px grey solid;
}

.filter-input {
    font-size: 18px;
}

.unit-grid {
    display: grid; /* Use CSS Grid for layout */
    // grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
    grid-template-columns: 
  repeat(auto-fit, minmax(284px, 1fr));
    grid-auto-columns: minmax(0, 1fr);
    gap: 20px; /* Space between items */
    justify-items: center; /* Center items within the grid */
    width: 80%; /* Ensure the grid container takes full width */
    max-width: 1050; /* Set a max-width to control the grid width */
    margin: 0 auto; /* Center the grid container */
}

/* AvailabilityCard.css */
.unit-card {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: $p-font;
    width: 100%;
    // flex: 1 0 200px; /* Set flex-basis to 200px and allow growth and shrinkage */
    min-width: 284px; /* Optional: Set a max-width for the cards */
    max-width: 325px;
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}
  
.unit-card img {
    height: 150px;
    object-fit: scale-down;
    width: auto;
    border-radius: 8px;
}

p.list-item {
    margin: 0.5rem 0px;
}
  
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    text-align: center; /* Center-align the text */
    font-family: $p-font;
}

.modal-buttons {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px;
    margin-top: 20px;
}
  
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
  
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
  
.tour-button,
.interest-button,
.apply-button {
    display: block;
    margin: 10px 0;
    padding: 10px;
    background-color: $orange;
    color: $white;
    border: none;
    border-radius: 40px;
    font-size: 1rem;
    font-family: $header-font;
    cursor: pointer;
    text-align: center;
}

.no-scroll {
    overflow: hidden;
}

.see-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  
.see-more button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: $orange;
    font-size: 1rem;
    color: $white;
    border: none;
    border-radius: 40px;
}

.loading-message,
.no-units-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  font-size: 1.3rem;
  color: #555;
  font-family: $p-font;
  width: 80%;
  text-align: center;
}